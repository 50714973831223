import React from "react"

import Layout from "../components/Layout"
import styled from "styled-components"

const ComingSoon = styled.div`
  font-size: 4em;
  font-family: monument, sans-serif;
  margin: auto;
  text-align: center;
`
export default () => {
  return (
    <Layout title="DOMINIKA BARSKA">
      <ComingSoon>Coming soon !</ComingSoon>
    </Layout>
  )
}
